import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Numeric from "./NumericInput";
import { VAT } from "../utils/constants";
import { isPurchasePriceIncludingVat } from "resources/utils";

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const TextDiv = styled.div`
  margin: 0.9rem 1.5rem 0 1.5rem;
`;

const DECIMALPLACES = 2;
const roundValueToMax = (value, max) => {
  // in case value is in the form of 3.000000000000001
  // if the precision is more than 6 decimal places, we can safely ignore it
  const formattedValue = Number(value.toFixed(6));
  const lowestDecimalNumber = 1 / Math.pow(10, DECIMALPLACES);
  
  if (formattedValue > max && formattedValue < max + lowestDecimalNumber) {
    return max + lowestDecimalNumber;
  }

  return formattedValue;
};

const round = (num, isRoundUp) => {
  if (isRoundUp) {
    return Math.ceil((num * 100)) / 100;
  }

  return Math.floor((num * 100)) / 100;
}

const formatValue = (value) => {
  return value === 0 ? value.toString() : value;
}

/**
 * main component
 */
const SumOrPercent = ({ input, valid, total, suffix, isVATUsed, max, values, onBlur, resetPercentage, meta, fixPercentage }) => {
  const [percentageValue, setPercentageValue] = useState();
 
  useEffect(() => {
    let VATvalue = total && isVATUsed ? total * VAT : 0;

    if (fixPercentage) {
      const purchasePrice = total ? total : 0;
      setPercentageValue(fixPercentage); // Set fixed percentage if provided
      let VATvalue = total && isVATUsed ? total * VAT : 0;
      
      const currencyData = round((purchasePrice * fixPercentage) / 100 + VATvalue, fixPercentage === 0 ? true : false);
      setPercentageValue(formatValue(fixPercentage));

      if (!isNaN(currencyData)) {
        input.onChange(formatValue(currencyData));
      }
      
      onBlurHandler({ value: formatValue(fixPercentage + suffix) });
    } else {
        // calculate and set the percentage value
        if (total && !isNaN(parseFloat(input.value)) && input.value >= 0) {
          console.log("Total, value : ", total, input.value)
          let percentage = roundValueToMax(((input.value - VATvalue) * 100 / total)).toFixed(2) * 1;
          setPercentageValue(percentage < 0 ? "" : formatValue(percentage));
          
          // force percentage to be 0 and re-validate
          if (resetPercentage) {
            if (percentage < 0) {
              input.onChange(null);
              onBlur({ value: null });
              input.onBlur();
            }
          }
        }
  
        if (total && !isNaN(parseFloat(percentageValue)) && percentageValue >= 0 && !input.value) {
          const currencyData = round((total * percentageValue) / 100 + VATvalue);
          input.onChange(formatValue(currencyData));
        }
    }
  }, [total, isVATUsed, input, meta, percentageValue, resetPercentage, onBlur, fixPercentage]);

  const handleCurrencyAmountChange = (evt) => {
    // console.log(`[handleCurrencyAmountChange] ${evt?.currentTarget.value}`);
    
    let VATvalue = total && isVATUsed ? total * VAT : 0;
    if (evt?.currentTarget.value) {
      const value =
        evt.currentTarget.value.replace("£", "").replace(/,/g, "") * 1;
      let percentage =
        roundValueToMax(((value - VATvalue) * 100 / total), max).toFixed(2) * 1;
      const rawPercent = (value - VATvalue) * 100 / total;

      if (total) {
        setPercentageValue(rawPercent < 0 ? "" : formatValue(percentage));
      }
      input.onChange(formatValue(value));

      onBlurHandler({ value: formatValue(value) });
    }else{
      input.onChange(null);
      setPercentageValue(null);

      onBlurHandler({ value: null });
    }
    input.onBlur();
  };

  const handlePercentageChange = (evt) => {
    const purchasePrice = total ? total : 0;
    // console.log(`[handlePercentageChange] ${evt?.currentTarget.value} --- ${percentageValue}, ${purchasePrice}`);
    
    if (evt?.currentTarget.value) {
      const value = evt?.currentTarget.value.replace(suffix, "") * 1;
      let VATvalue = total && isVATUsed ? total * VAT : 0;
      
      //Don't need to update the calculated value in case if the percentage is the same
      if (percentageValue && value.toString() === percentageValue.toString()) {
        onBlurHandler({ value: percentageValue.toString() + suffix });
        return;
      }
      
      const currencyData = round((purchasePrice * value) / 100 + VATvalue, value === 0 ? true : false);
      setPercentageValue(formatValue(value));

      if (!isNaN(currencyData)) {
        input.onChange(formatValue(currencyData));
      }
      
      onBlurHandler({ value: formatValue(value + suffix) });
    } else {
      input.onChange(null);
      setPercentageValue(null);
      onBlurHandler({ value: null });
    }

    input.onBlur();
  };

  const onBlurHandler = (e) => {
    onBlur(e);
  };

  return (
    <Container>
      <Numeric
        {...input}
        valid={valid}
        isCurrency={true}
        onBlur={(e) => { handleCurrencyAmountChange(e); }}
        onChange={() => { }}
        disabled={fixPercentage ? true : false}
      />
      <TextDiv>or</TextDiv>
      <Numeric
        value={percentageValue}
        valid={valid}
        decimalScale={2}
        suffix={suffix}
        onBlur={(e) => { handlePercentageChange(e); }}
        onChange={() => { }}
        placeholder="%"
        fixedDecimalScale={true}
        disabled={fixPercentage ? true : false}
      />
    </Container>
  );
};

export default SumOrPercent;
