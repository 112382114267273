import React from "react";
import { Form, Field, FormSpy } from "react-final-form";
import { Link } from "react-router-dom";
import ReactDOMServer from 'react-dom/server'
import styled from "styled-components";
import { error } from "../../resources/error.css";
import {
  ErrorMessage,
  RadioWrapper,
  FieldInnerContainer,
  FieldInputWrapper,
  H3,
  NoteWrapper,
  TextArea,
} from "../theming/common";
import {
  isFieldValidAndTouched,
  composeValidators,
  isCarsAssetSelected,
  isCars,
} from "../utils/form";
import {
  requiredValidator,
  numberValidator,
  dynamicTotalPriceValidator,
  dynamicTermLengthValidator,
  dynamicDepositValidator,
  dynamicLumpSumValidator,
  comissionValidator,
  getTotalForCommissionCalculation
} from "../utils/validators";
import RadioButton from "./RadioButton";
import Numeric from "./NumericInput";
import { lumpSumOptions } from "../utils/constants";
import MaximumTermAndAgeTable from "./MaximumTermAndAge";
import SumOrPercent from "./SumOrPercentInput";
import LombardRoutes from "../utils/routes";
import { trackFieldInput, trackRadioButtonSelection, trackValidationError } from "resources/analyticsHelper";
import { isAssetCars, isPurchasePriceIncludingVat, isDepositIncludingVat } from "resources/utils";
import { colours } from "../../resources/global-styles.js";

const Small = styled.small`
  display: block;
  width: 100%;
`;

const InnerTextFieldWrapper = styled.div`
  padding: 1rem 1rem 0 0;
`;

const LumpSumRadioButtonsContainer = styled.div`
  width: 100%;
`;

const ErrorContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`;

const ErrorLink = styled(Link)`
  color: ${colours.warning_link};
  font-weight: bold;
  text-decoration: underline;
  cursor:pointer;
`;

const getAssetTypeName = (assetTypes, selectedAssetId) => {
  if (assetTypes && selectedAssetId) {
    var asset = assetTypes.filter((x) => x.id === selectedAssetId)[0];
    return asset.name;
  }
};

const showLumpSum = (assetTypes, selectedAssetId) => {
  if (assetTypes && selectedAssetId) {
    var asset = assetTypes.filter((x) => x.id === selectedAssetId)[0];
    return asset.allowLumpSumPayment;
  }

  return false;
};

const handleAssetTypeIdChange = (form, assetTypes, vatDefer, value) => {
  if (!isCarsAssetSelected(assetTypes, value)) {
    form.change("lumpsumset", null);
    form.change("finalLumpSum", null);
  } else {
    form.change("vatDefer", null);
    form.change("vatDeferDetails", null);
  }
};

const AssetDetailsForm = ({
  onSubmit,
  initialValues,
  assetTypes,
  hideAssetInformation,
  footer,
  onValuesChange,
}) => {
  let fixPercentage = 2.25;

  if (initialValues && initialValues.amountFinanced && initialValues.amountFinanced > 50000) {
    fixPercentage = 2;
  }

  const getReactElemContent = (elem) => {
    if (!elem) {
      return '';
    }

    if (typeof elem === 'string') {
      return elem;
    }

    const list = elem.props && elem.props.children;
    if (list instanceof Array) {
      return list.map(getReactElemContent).join('');
    } else if (typeof list === 'string') {
      return list;
    }

    return '---';
  };

  const onBlurFieldHandler = (e, fieldLabel, meta, forceErrMsg) => {
    // console.log(`>>> onBlurFieldHandler`, '---', e.name, '---', e.value, '---', fieldLabel, '---', e, '---', meta);

    trackFieldInput(fieldLabel, e.value);

    if (meta) {
      let errMsg = meta.error;
      if (meta.error && meta.error.props) {
        errMsg = getReactElemContent(meta.error);
      }

      if (forceErrMsg) {
        errMsg = forceErrMsg;
      }

      if (meta && meta.error) {
        trackValidationError(errMsg, fieldLabel, 'Asset details')
      }
    }
  };

  const onRadioFieldSelection = (e, fieldLabel, option) => {
    // console.log(`>>> onRadioFieldSelection`, e, fieldLabel, option);
    trackRadioButtonSelection(fieldLabel, option.label);
  };

  const vatDefer = [
    {
      value: 'yes',
      label: 'Yes',
      // subtext: '<br />VAT defers are subject to customer meeting our minimum creteria. Please contact us for more information.<br /><br /> 1. some text <br /> 2. some other text',
    },
    {
      value: 'no',
      label: 'No',
    },
  ];

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, pristine, values, onBlurHandler }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <input
            autoComplete="off"
            name="hidden"
            type="text"
            style={{ display: "none" }}
          />
          <FormSpy
            subscription={{ values: true, pristine: true }}
            onChange={(state) => {
              if (!state.pristine) {
                const { values } = state;
                onValuesChange(values);
              }
            }}
          />
          {hideAssetInformation !== true && (
            <>
              <RadioWrapper>
                {assetTypes &&
                  assetTypes.length &&
                  assetTypes.map((x) => {
                    const option = {
                      value: x.id,
                      label: x.name,
                    };
                    return (
                      <Field
                        name="assetTypeId"
                        type="radio"
                        value={x.id}
                        key={`assetType_${x.id}`}
                        validate={requiredValidator}
                      >
                        {({ input }) => {
                          return (
                            <RadioButton
                              key={`radio_assetType_${x.id}`}
                              option={option}
                              bSelected={input.checked}
                              handleChange={(...args) => {
                                handleAssetTypeIdChange(
                                  form,
                                  assetTypes,
                                  values.vatDefer,
                                  ...args
                                );
                                input.onChange(...args);
                                onRadioFieldSelection(input, 'What type of asset is needed?', option);
                              }}
                            />
                          );
                        }}
                      </Field>
                    );
                  })}
              </RadioWrapper>
              {form.getState().touched.assetTypeId && !values.assetTypeId && (
                <ErrorMessage>Please select asset type</ErrorMessage>
              )}
              {values.assetTypeId && (
                <MaximumTermAndAgeTable
                  assetKey={getAssetTypeName(assetTypes, values.assetTypeId)}
                />
              )}
            </>
          )}
          {values.assetTypeId && (
            <>
              <Field
                name="numberOfAssets"
                validate={composeValidators(numberValidator(1, 10))}
              >
                {({ input, meta, onBlurHandler }) => (
                  <FieldInnerContainer>
                    <h3>Total number of assets needed</h3>
                    <FieldInputWrapper>
                      <Numeric
                        {...input}
                        onBlur={(e) => {
                          input.onBlur(e); 
                          onBlurFieldHandler(
                            input, 
                            'Total number of assets needed', 
                            meta, 
                            `Unfortunately we're unable to quote online when the number of assets is more than 10. You can still contact us to discuss your requirements`
                          );
                        }}
                        valid={isFieldValidAndTouched(meta)}
                      />
                    </FieldInputWrapper>
                    {meta.error &&
                      meta.touched &&
                      (!values.numberOfAssets ||
                        values.numberOfAssets <= 10) && (
                        <ErrorMessage>{meta.error}</ErrorMessage>
                      )}
                    {meta.error && meta.touched && values.numberOfAssets > 10 && (
                      <ErrorMessage>
                        Unfortunately we're unable to quote online when the
                        number of assets is more than 10. You can still{" "}
                        <ErrorLink to={LombardRoutes.unableToProceedRoute()}>
                          contact us
                        </ErrorLink>{" "}
                        to discuss your requirements
                      </ErrorMessage>
                    )}
                  </FieldInnerContainer>
                )}
              </Field>
              <Field
                name="purchasePrice"
                validate={composeValidators(
                  dynamicTotalPriceValidator(form, assetTypes)
                )}
              >
                {({ input, meta }) => (
                  <FieldInnerContainer>
                    <H3>
                      {/* What's the total{" "}
                      {isCarsAssetSelected(assetTypes, values.assetTypeId, values.vatDefer)
                        ? "gross"
                        : "net"}{" "}
                      cost? (
                      {isCarsAssetSelected(assetTypes, values.assetTypeId, values.vatDefer)
                        ? "including"
                        : "excluding"}{" "}
                      VAT) */}
                      {isPurchasePriceIncludingVat(assetTypes, values.assetTypeId, values.vatDefer)
                        ? `What's the total gross cost? (including VAT)`
                        : `What's the total net cost? (excluding VAT)`}
                    </H3>
                    <Small>
                      This is the sum cost of assets (maximum £250,000)
                    </Small>
                    <FieldInputWrapper>
                      <Numeric
                        {...input}
                        onBlur={(e) => {
                          input.onBlur(e); 
                          onBlurFieldHandler(input, `What's the total net cost? (excluding VAT)`, meta); 
                        }}
                        valid={isFieldValidAndTouched(meta)}
                        isCurrency={true}
                      />
                    </FieldInputWrapper>
                    {meta.error && meta.touched && (
                      <ErrorMessage>{meta.error}</ErrorMessage>
                    )}
                  </FieldInnerContainer>
                )}
              </Field>
              <Field
                name="termInMonths"
                validate={composeValidators(
                  dynamicTermLengthValidator(assetTypes, form, `What's the term length needed? (months)`)
                )}
              >
                {({ input, meta }) => (
                  <FieldInnerContainer>
                    <h3>What's the term length needed? (months)</h3>
                    <FieldInputWrapper>
                      <Numeric
                        {...input}
                        onBlur={(event) => { 
                          input.onBlur(event); 
                          onBlurFieldHandler(input, `What's the term length needed? (months)`, meta); 
                        }}
                        valid={isFieldValidAndTouched(meta)}
                        suffix={input.value > 1 ? " months" : " month"}
                      />
                    </FieldInputWrapper>
                    {meta.error && meta.touched && (
                      <ErrorMessage>{meta.error}</ErrorMessage>
                    )}
                  </FieldInnerContainer>
                )}
              </Field>




              {!isCars(assetTypes, values.assetTypeId) && (
              <>
                <h3>Are you requesting 3 months VAT defer?</h3>
                <RadioWrapper
                  layout="2-column"
                >
                  {vatDefer.map((x) => {
                    return (
                      <>
                      <Field
                        name="vatDefer"
                        type="radio"
                        value={x.value}
                        key={`netAssets_${x.value}`}
                        validate={requiredValidator}
                      >
                        {({ input }) => {
                          return (
                            <RadioButton
                              key={`radio_netAssets_${x.value}`}
                              option={x}
                              bSelected={input.checked}
                              handleChange={(e) => {
                                input.onChange(e);
                                onRadioFieldSelection(input, `Are you requesting 3 months VAT defer?`, x);
                              }}
                              layout="1-column"
                            />
                          );
                        }}
                      </Field>
                      </>
                    );
                  })}
                </RadioWrapper>
                {form.getState().touched.vatDefer && !values.vatDefer && (
                  <ErrorMessage>Please select if you want to defer VAT</ErrorMessage>
                )}
              </>
              )}

              
              {!isAssetCars(assetTypes, values.assetTypeId) && 
              values.vatDefer === 'yes' &&
              <Field 
                name="vatDeferDetails" 
                validate={requiredValidator}
              >
                {({ input, meta }) => (
                  <FieldInnerContainer>
                    <h3>What are the reasons to defer VAT?</h3>
                    <Small>
                      1. Why is the deferral required (e.g. trading cycle, competitive position etc.)<br />
                      2. How the VAT will be repaid (e.g. normal trading revenues etc.)<br /><br />
                    </Small>
                    <FieldInputWrapper>
                      <TextArea
                        rows="6"
                        {...input}
                        onBlur={(event) => {
                          input.onBlur(event); 
                          onBlurFieldHandler(input, `Detail reasons for deferring VAT`, meta); 
                        }}
                      />
                    </FieldInputWrapper>
                    {meta.error && meta.touched && (
                      <ErrorMessage>Please enter VAT defer details</ErrorMessage>
                    )}
                  </FieldInnerContainer>
                )}
              </Field>}






              <Field
                name="deposit"
                validate={composeValidators(
                  dynamicDepositValidator(form, assetTypes, values.vatDefer)
                )}
              >
                {({ input, meta }) => (
                  <FieldInnerContainer>
                    <h3>
                      {isAssetCars(assetTypes, values.assetTypeId)
                        ? `What's the deposit?`
                        : values.vatDefer === 'yes'
                          ? `What's the deposit? (excluding VAT)`
                          : `What's the deposit? (including VAT)`}
                    </h3>
                    <FieldInputWrapper>
                      <SumOrPercent
                        onBlur={(e) => {
                          // console.log(form.getState());
                          const val = form.getState().values.deposit;
                          const errMsg = dynamicDepositValidator(form, assetTypes, values.vatDefer)(val);
                          onBlurFieldHandler(
                            { value: val },
                            `What's the deposit? ${isDepositIncludingVat(assetTypes, values.assetTypeId, values.vatDefer) ? '' : '(including VAT)'}`, 
                            { error: errMsg }
                          );
                        }}
                        input={input}
                        valid={isFieldValidAndTouched(meta)}
                        total={values.purchasePrice}
                        suffix={isDepositIncludingVat(assetTypes, values.assetTypeId, values.vatDefer) ? "% + VAT" : "%"}
                        isVATUsed={isDepositIncludingVat(assetTypes, values.assetTypeId, values.vatDefer)}
                      />
                    </FieldInputWrapper>
                    {meta.error && meta.touched && (
                      <ErrorMessage>{meta.error}</ErrorMessage>
                    )}
                  </FieldInnerContainer>
                )}
              </Field>
              {showLumpSum(assetTypes, values.assetTypeId) && (
                <FieldInnerContainer>
                  <RadioWrapper>
                    <H3>Add a final lump sum (balloon) payment?</H3>
                    <Small>
                      Lump sum eligibility will need to be confirmed by Lombard
                      and may be subject to change
                    </Small>
                    <LumpSumRadioButtonsContainer>
                      <FieldInputWrapper>
                        <Field
                          name="lumpsumset"
                          type="radio"
                          value={lumpSumOptions[0].value}
                          key={`lso_${lumpSumOptions[0].value}`}
                          validate={requiredValidator}
                        >
                          {({ input }) => {
                            return (
                              <>
                                <RadioButton
                                  key={`radio_lso_${lumpSumOptions[0].value}`}
                                  option={lumpSumOptions[0]}
                                  bSelected={input.checked}
                                  handleChange={(...args) => {
                                    input.onChange(...args);
                                    onRadioFieldSelection(input, 'Add a final lump sum (balloon) payment?', lumpSumOptions[0]);
                                  }}
                                  layout="fullwidth"
                                >
                                  <Field
                                    name="finalLumpSum"
                                    validate={composeValidators(
                                      requiredValidator,
                                      dynamicLumpSumValidator(form, assetTypes, values.vatDefer)
                                    )}
                                  >
                                    {({ input, meta }) => (
                                      <InnerTextFieldWrapper>
                                        <label>Final lump sum payment</label>
                                        <Numeric
                                          {...input}
                                          valid={isFieldValidAndTouched(meta)}
                                          onBlur={() => {
                                            onBlurFieldHandler(input, 'Final lump sum payment', meta); 
                                          }}
                                          isCurrency={true}
                                        />
                                      </InnerTextFieldWrapper>
                                    )}
                                  </Field>
                                </RadioButton>
                              </>
                            );
                          }}
                        </Field>
                      </FieldInputWrapper>
                    </LumpSumRadioButtonsContainer>
                    {form.getState().errors.finalLumpSum &&
                      form.getState().touched.finalLumpSum &&
                      form.getState().values.lumpsumset !== lumpSumOptions[1].value && 
                      (
                        <ErrorContainer>
                          <ErrorMessage>
                            {form.getState().errors.finalLumpSum}
                          </ErrorMessage>
                        </ErrorContainer>
                      )
                    }
                    <LumpSumRadioButtonsContainer>
                      <FieldInputWrapper>
                        <Field
                          name="lumpsumset"
                          type="radio"
                          value={lumpSumOptions[1].value}
                          key={`lso_${lumpSumOptions[1].value}`}
                        >
                          {({ input }) => {
                            return (
                              <RadioButton
                                key={`radio_lso_${lumpSumOptions[1].value}`}
                                option={lumpSumOptions[1]}
                                bSelected={input.checked}
                                handleChange={(...args) => {
                                  input.onChange(...args);
                                  onRadioFieldSelection(input, 'Add a final lump sum (balloon) payment?', lumpSumOptions[1]);
                                }}
                                layout="fullwidth"
                              />
                            );
                          }}
                        </Field>
                      </FieldInputWrapper>
                    </LumpSumRadioButtonsContainer>
                    {form.getState().touched.lumpsumset &&
                      !values.lumpsumset && (
                        <ErrorMessage>Please select an option</ErrorMessage>
                      )}
                  </RadioWrapper>
                </FieldInnerContainer>
              )}
              {values.lumpsumset && values.lumpsumset == lumpSumOptions[0].value && (
                <NoteWrapper>
                <Small>
                  <b>
                    Note: the final lump sum payment is due one month after the specified term length. For example, if the specified term length is 48 months, the quote shown will be for 48 months with the balloon payment due on the 49<sup>th</sup> month.
                  </b>
                </Small>
                </NoteWrapper>
              )}
              <Field
                name="commission"
                validate={composeValidators(
                  comissionValidator(assetTypes)
                )}
              >
                {({ input, meta }) => (
                  <FieldInnerContainer>
                    <H3>What's your commission for this quote?</H3>
                    <Small>
                    A fixed commission of 2.25% is applied for amount financed up to and including £50,000.00, and a fixed commission of 2.00% is applied for amount financed from £50,000.01 to £250,000.00.
                    </Small>
                    <FieldInputWrapper>
                      <SumOrPercent
                        onBlur={(e) => {
                          const val = form.getState().values.commission;
                          const vals = form.getState().values;
                          const errMsg = comissionValidator(assetTypes)(val, vals);
                          
                          onBlurFieldHandler(
                            e,
                            `What's your commission for this quote?`, 
                            { error: errMsg }
                          );
                        }}
                        input={input}
                        valid={isFieldValidAndTouched(meta)}
                        total={getTotalForCommissionCalculation(values, assetTypes)}
                        suffix="%"
                        isVATUsed={false}
                        max={3}
                        fixPercentage={fixPercentage}
                      />
                    </FieldInputWrapper>
                    {meta.error && meta.touched && (
                      <ErrorContainer>
                        <ErrorMessage>{meta.error}</ErrorMessage>
                      </ErrorContainer>
                    )}
                  </FieldInnerContainer>
                )}
              </Field>
            </>
          )}
          {footer}
        </form>
      )}
    />
  );
};

export default AssetDetailsForm;
