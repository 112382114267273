import React from "react";
import { Form, Field, FormSpy } from "react-final-form";

import { trackFieldInput, trackRadioButtonSelection, trackValidationError } from "resources/analyticsHelper";
import { isPurchasePriceIncludingVat, isDepositIncludingVat, isAssetCars } from "resources/utils";
import {
  ErrorMessage,
  FieldInnerContainer,
  FieldInputWrapper,
  RadioWrapper,
} from "../../../theming/common";
import {
  isFieldValidAndTouched,
  composeValidators,
  isCarsAssetSelected,
  isCars,
} from "../../../utils/form";
import {
  requiredValidator,
  currencyValidator,
  dynamicTermLengthValidator,
  dynamicDepositValidator,
  dynamicLumpSumValidator,
  comissionValidator,
  getTotalForCommissionCalculation
} from "../../../utils/validators";
import RadioButton from "../../../components/RadioButton";
import Numeric from "../../../components/NumericInput";
import SumOrPercent from "../../../components/SumOrPercentInput";
import { useLombardContext } from "../../../context/lombardContext";


const QuoteSummaryEditForm = ({
  onSubmit,
  initialValues,
  assetTypes,
  footer,
  onValuesChange,
}) => {
  const { state, actions } = useLombardContext();

  let fixPercentage = 2.25;

  if (initialValues && initialValues.amountFinanced && initialValues.amountFinanced > 50000) {
    fixPercentage = 2;
  }

  const onBlurFieldHandler = (e, fieldLabel, meta) => {
    // console.log(`>>> onBlurFieldHandler`, e.name, e.value, fieldLabel, e, meta);

    trackFieldInput(fieldLabel, e.value);

    if (meta && meta.error) {
      const errMsg = meta.error;
      trackValidationError(errMsg, fieldLabel, 'Quote summary')
    }
  };

  
  const onRadioFieldSelection = (e, fieldLabel, option) => {
    // console.log(`>>> onRadioFieldSelection`, e, fieldLabel, option);
    
    trackRadioButtonSelection(fieldLabel, option.label);

    // console.log(`[onRadioFieldSelection] ${option}`);
  };

  const vatDeferOptions = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
  ];

  const resetDepositAndCommission = (values) => {
    // actions.fillQuoteFromRetrievedInformation(data);
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, values }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <input
            autoComplete="off"
            name="hidden"
            type="text"
            style={{ display: "none" }}
          />
          <FormSpy
            subscription={{ values: true, pristine: true }}
            onChange={(state) => {
              if (!state.pristine) {
                const { values } = state;
                onValuesChange(values);
              }
            }}
          />
          <Field
            name="purchasePrice"
            validate={composeValidators(currencyValidator(1, 250000))}
          >
            {({ input, meta }) => (
              <FieldInnerContainer>
                <label>
                  {isPurchasePriceIncludingVat(assetTypes, values.assetTypeId, values.vatDefer)
                    ? `Total gross cost? (including VAT)`
                    : `Total net cost? (excluding VAT)`}
                  {/* Total{" "}
                  {isCarsAssetSelected(assetTypes, values.assetTypeId, values.vatDefer)
                    ? "gross"
                    : "net"}{" "}
                  cost (
                  {isCarsAssetSelected(assetTypes, values.assetTypeId, values.vatDefer)
                    ? "including"
                    : "excluding"}{" "}
                  VAT) */}
                </label>
                <FieldInputWrapper>
                  <Numeric
                    {...input}
                    valid={isFieldValidAndTouched(meta)}
                    isCurrency={true}
                    onBlur={(e) => { 
                      input.onBlur(); 
                      onBlurFieldHandler(input, (isCarsAssetSelected(assetTypes, values.assetTypeId, values.vatDefer) ? 'Total gross cost (including VAT)' : 'Total net cost (excluding VAT)'), meta); 
                    }}
                  />
                </FieldInputWrapper>
                {meta.error && meta.touched && (
                  <ErrorMessage>{meta.error}</ErrorMessage>
                )}
              </FieldInnerContainer>
            )}
          </Field>
          <Field
            name="termInMonths"
            validate={composeValidators(
              dynamicTermLengthValidator(assetTypes, form)
            )}
          >
            {({ input, meta }) => (
              <FieldInnerContainer>
                <label>Term length (months)</label>
                <FieldInputWrapper>
                  <Numeric
                    {...input}
                    valid={isFieldValidAndTouched(meta)}
                    suffix={input.value > 1 ? " months" : " month"}
                    onBlur={(e) => { input.onBlur(); onBlurFieldHandler(input, `Term length (months)`, meta); }}
                  />
                </FieldInputWrapper>
                {meta.error && meta.touched && (
                  <ErrorMessage>{meta.error}</ErrorMessage>
                )}
              </FieldInnerContainer>
            )}
          </Field>



          {!isCars(assetTypes, values.assetTypeId) && (
          <>
            <label>Are you requesting 3 months VAT defer?</label>
            <RadioWrapper
              layout="2-column"
            >
              {vatDeferOptions.map((x) => {
                return (
                  <Field
                    name="vatDefer"
                    type="radio"
                    value={x.value}
                    key={`netAssets_${x.value}`}
                    validate={requiredValidator}
                  >
                    {({ input }) => {
                      return (
                        <RadioButton
                          key={`radio_netAssets_${x.value}`}
                          option={x}
                          bSelected={input.checked}
                          handleChange={(e) => {
                            input.onChange(e);
                            onRadioFieldSelection(input, `Are you requesting 3 months VAT defer?`, x);
                            // console.log('----------------- changed', x);
                          }}
                          layout="1-column"
                        />
                      );
                    }}
                  </Field>
                );
              })}
            </RadioWrapper>
            {form.getState().touched.netAssets && !values.netAssets && (
              <ErrorMessage>Please select if you want to defer VAT</ErrorMessage>
            )}
          </>
          )}



          <Field
            name="deposit"
            validate={composeValidators(
              dynamicDepositValidator(form, assetTypes, values.vatDefer)
            )}
          >
            {({ input, meta }) => (
              <FieldInnerContainer>
                <label>
                  {/* {isDepositIncludingVat(assetTypes, values.assetTypeId, values.vatDefer) 
                    ? `What's the deposit? (including VAT)` 
                    : `What's the deposit?`} */}
                  {/* What's the deposit?{" "}
                  {isCarsAssetSelected(assetTypes, values.assetTypeId, values.vatDefer)
                    ? ""
                    : "(including VAT)"} */}
                  {isAssetCars(assetTypes, values.assetTypeId)
                        ? `What's the deposit?`
                        : values.vatDefer === 'yes'
                          ? `What's the deposit? (excluding VAT)`
                          : `What's the deposit? (including VAT)`}
                </label>
                <FieldInputWrapper>
                  <SumOrPercent
                    meta={meta}
                    input={input}
                    valid={isFieldValidAndTouched(meta)}
                    total={values.purchasePrice}
                    suffix={isDepositIncludingVat(assetTypes, values.assetTypeId, values.vatDefer) ? "% + VAT" : "%"}
                    isVATUsed={isDepositIncludingVat(assetTypes, values.assetTypeId, values.vatDefer)}
                    resetPercentage={true}
                    onBlur={(e) => {
                      const val = form.getState().values.deposit;
                      const errMsg = dynamicDepositValidator(form, assetTypes, values.vatDefer)(val);
                      onBlurFieldHandler(
                        { value: val },
                        `What's the deposit? ${isCarsAssetSelected(assetTypes, values.assetTypeId, values.vatDefer) ? '' : '(including VAT)'}`, 
                        { error: errMsg }
                      );
                    }}
                  />
                </FieldInputWrapper>
                {meta.error && meta.touched && (
                  <ErrorMessage>{meta.error}</ErrorMessage>
                )}
              </FieldInnerContainer>
            )}
          </Field>
          {
            values.lumpsumset === "yes" &&
            // isCarsAssetSelected(assetTypes, values.assetTypeId) && 
            (
              <Field
                name="finalLumpSum"
                validate={composeValidators(dynamicLumpSumValidator(form, assetTypes))}
              >
                {({ input, meta }) => (
                  <FieldInnerContainer>
                    <FieldInputWrapper>
                      <label>Final lump sum (balloon)</label>
                      <Numeric
                        {...input}
                        valid={isFieldValidAndTouched(meta)}
                        isCurrency={true}
                      />
                    </FieldInputWrapper>
                    {meta.error && meta.touched && (
                      <ErrorMessage>{meta.error}</ErrorMessage>
                    )}
                  </FieldInnerContainer>
                )}
              </Field>
            )}
          <Field
            name="commission"
            validate={composeValidators(
              comissionValidator(assetTypes)
            )}
          >
            {({ input, meta }) => (
              <FieldInnerContainer>
                <label>
                A fixed commission of 2.25% is applied for amount financed up to and including £50,000.00, and a fixed commission of 2.00% is applied for amount financed from £50,000.01 to £250,000.00.
                </label>
                <FieldInputWrapper>
                <SumOrPercent
                  onBlur={(e) => {
                    const val = form.getState().values.commission;
                    const vals = form.getState().values;
                    const errMsg = comissionValidator(assetTypes)(val, vals);
                    
                    onBlurFieldHandler(
                      e,
                      `What's your commission for this quote?`, 
                      { error: errMsg }
                    );
                  }}
                  input={input}
                  valid={isFieldValidAndTouched(meta)}
                  total={getTotalForCommissionCalculation(values, assetTypes)}
                  suffix="%"
                  isVATUsed={false}
                  max={3}
                  fixPercentage={fixPercentage}
                />
                </FieldInputWrapper>
                {meta.error && meta.touched && (
                  <ErrorMessage>{meta.error}</ErrorMessage>
                )}
              </FieldInnerContainer>
            )}
          </Field>
          {footer}
        </form>
      )}
    />
  );
};

export default QuoteSummaryEditForm;
