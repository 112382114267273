import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import {
  formatter,
  currencyNoDecimalFormatter,
  numberTwoDecimalsFormatter,
} from "resources/constants";
import { useHistory } from "react-router";
import { Form, Field, FormSpy } from "react-final-form";

import {
  phoneValidator,
  emailValidator,
  requiredPhoneValidator,
  customRequiredValidator
} from "../../../utils/validators";
import FieldInput from "../../../components/FieldInput";
import { ErrorMessage } from "../../../theming/common";
import { colors } from "../../../theming/variables";
import { TextLink, Button, H3 } from "../../../theming/common";
import { useAppContext } from "../../../../context/appContext";
import { useLombardContext } from "../../../context/lombardContext";

import { isFieldValidAndTouched, composeValidators } from "../../../utils/form";
import { quoteStages } from "../../../utils/constants";
import ApiFetchService from "../../../utils/dataFetching";
import { isCarsAssetSelected } from "../../../utils/form";
import { VAT } from "../../../utils/constants";

import Warning from "../../../components/Warning";
import PrevNextButtons from "../../../components/PrevNextButtons";
import SaveQuote from "../../../components/SaveQuote";
import BrokerDetailsFieldsSimplified from "../../../components/BrokerDetailsFieldsSimplified";

import QuoteSummaryEditForm from "./QuoteSummaryEditForm";
import QuoteInformationTable from "./QuoteInformationTable";
import { ReactComponent as EditIcon } from "resources/images/edit_purple.svg";
import { trackButtonClick } from 'resources/analyticsHelper';
import { isDepositIncludingVat } from "resources/utils";
import { colours } from 'resources/global-styles';

const Container = styled.div`
  margin-bottom: 1rem;
`;

const CancelButton = styled.button`
  height: 60px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: ${colours.btn_copy};
  margin-left: 5rem;

  &:hover {
    text-decoration: underline;
  }
`;

const SummaryHeader = styled.div`
  border: 2px solid ${colors.mediumGreyBorder};
  padding: 1rem;
  display: flex;
  align-items: center;
`;

const FixedWidthTextLink = styled(TextLink)`
  width: 120px;
  margin-left: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
`;

const FormContainer = styled.div`
  border: 2px solid ${colors.lightGrey};
  padding: 1rem;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 1rem 0;
`;

const Footer = (handleCancelClick) => {
  return (
    <ButtonContainer>
      <Button type="submit">Save</Button>
      <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
    </ButtonContainer>
  );
};

let currQuoteId = null;
let emailIsValid = false;

const QuoteSummary = () => {
  const { state, actions } = useLombardContext();
  const { assetTypes } = state;
  const assetDetails = state.quote.assetDetails;
  const quoteSummary = state.quoteSummary;
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [summaryEmail, setSummaryEmail] = useState(false);
  const [isSaveMenuShown, setIsSaveMenuShown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formsValidationStatus, setFormsValidationStatus] = useState({});
  const [jsonData, setJsonData] = useState({});

  useEffect(() => {
    // console.log('state.quoteSummary.retrieved', state.quoteSummary.retrieved);
    
    if (!state.quoteSummary.retrieved && !isEditing) {
      setIsLoading(true);

      
      // console.log(state.quote);
      const obj = { ...state.quote, assetDetails: { ...state.quote.assetDetails } };
      if (!isCarsAssetSelected(assetTypes, obj.assetDetails.assetTypeId) && obj.assetDetails.vatDefer === 'yes') {
        obj.assetDetails.deposit = obj.assetDetails.deposit - (obj.assetDetails.purchasePrice * VAT);
      }

      ApiFetchService.GenerateQuote(state.quote).then((data) => {
        setIsLoading(false);

        actions.addOrUpdatePageData("quoteSummary", data);
        setJsonData(data);

        currQuoteId = data.quoteId;
      });
    }
    const newSummary = Object.assign(state.quoteSummary, { retrieved: false });
    actions.addOrUpdatePageData("quoteSummary", newSummary);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.quote.assetDetails]);

  const onSubmit = useCallback(
    (values) => {
      // let newDeposit = values.deposit;
      if (isCarsAssetSelected(assetTypes, values.assetTypeId)) {
        values.amountFinanced = values.purchasePrice - values.deposit;
      }
  
      if (!isCarsAssetSelected(assetTypes, values.assetTypeId) && values.vatDefer === 'yes') {
        values.amountFinanced = values.purchasePrice - values.deposit;
        
        // TODO: change values.deposit to values.deposit + VAT
        // non-car + yes
        // NOTE: make sure this change doesn't show up on front end.
        // newDeposit = values.deposit + values.purchasePrice * VAT;
      }
      
      if (!isCarsAssetSelected(assetTypes, values.assetTypeId) && values.vatDefer === 'no') {
        values.amountFinanced = values.purchasePrice + (values.purchasePrice * VAT) - values.deposit;
      }

      setIsEditing(false);

      actions.setQuoteInformation("assetDetails", { ...values });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.quote.activeStageId]
  );

  const onValuesChange = useCallback(
    (values) => {
      if (isCarsAssetSelected(assetTypes, values.assetTypeId)) {
        values.amountFinanced = values.purchasePrice - values.deposit;
      }
  
      if (!isCarsAssetSelected(assetTypes, values.assetTypeId) && values.vatDefer === 'yes') {
        values.amountFinanced = values.purchasePrice - values.deposit;
      }
      
      if (!isCarsAssetSelected(assetTypes, values.assetTypeId) && values.vatDefer === 'no') {
        values.amountFinanced = values.purchasePrice + (values.purchasePrice * VAT) - values.deposit;
      }

      actions.setQuoteInformation("assetDetails", { ...values });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.quote.activeStageId]
  );

  const handleEditClick = () => {
    trackButtonClick('Button', 'Edit');
    setIsEditing(true);
  };
  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleBackClick = useCallback(() => {
    actions.setStage(state.quote.activeStageId - 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.quote.activeStageId]);
  
  const handleNextClick = useCallback(() => {
    actions.setStage(state.quote.activeStageId + 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.quote.activeStageId]);
  
  const history = useHistory();
  const appContext = useAppContext();
  const appActions = appContext.actions;

  const handleBackClickReturnHomePage = () => {
    actions.clearContextState();
    appActions.clearContextState();
    history.push("/");
  };

  const handleSubmitToLombardClick = useCallback(() => {
    if (isSubmitting) {
      return;
    }

    const allRequiredFormsAreValid = true;

    if (allRequiredFormsAreValid) {
      setIsSubmitting(true);

      // NOTE: very ugly implementation, but it works
      // REFACTOR:
      //  - change to react way of detecting/triggering form submission
      const efs = document.querySelectorAll('[type="email"]');
      efs[1].focus();
      efs[0].focus();

      if (!emailIsValid) {
        setIsSubmitting(false);
        return;
      }

      ApiFetchService.SubmitFinanceApplication(
        // state.quoteSummary.quoteId,
        currQuoteId,
        state.supportingDetails,
        state.prevReferenceNumber,
        state.prevQuoteId,
      )
        .then((data) => {
          actions.setStage(state.quote.activeStageId + 2);
          actions.addOrUpdatePageData("referenceNumber", data.referenceNumber);
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // privacyNoteConfirmed,
    formsValidationStatus,
    state.supportingDetails,
    isSubmitting,
  ]);

  const onBlurBrokerDetailsHandler = (val) => {
    // console.log('>> onBlurBrokerDetailsHandler');
  };

  const updateFormsValidationStatus = (valid, formKey, values) => {
    const { email } = values;
    actions.setSupportingDetails(formKey, { email });
  };

  const handleFormSpyOnChange = (e) => {
    // console.log('>> handleFormSpyOnChange', e);
    // console.log('>> handleFormSpyOnChange, e.pristine, e.valid', e.pristine, e.valid);
    
    emailIsValid = !e.pristine && e.valid;

    const { valid, values } = e;
    if (!e.pristine) {
      updateFormsValidationStatus(valid, values.formKey, values);
    } else {
      
    }
  };

  const getNextStageName = useCallback(() => {
    const nextStageId = state.quote.activeStageId + 1;
    const nextStage = quoteStages.filter((x) => x.id === nextStageId)[0];
    return nextStage.label;
  }, [state.quote.activeStageId]);

  useEffect(() => {
    // disable page scrolling if modal open
    document.body.style.overflow = isSaveMenuShown ? "hidden" : "unset";
  }, [isSaveMenuShown]);

  return (
    <Container>
      <h1>Quote summary {state.isFromReferenceNumber ? "(ref: " + state.prevReferenceNumber + ")" : ""}</h1>
      {isEditing === false && (
        <SummaryHeader>
          <div>
            You requested a <strong>fixed rate hire purchase</strong> quote for
            a total{" "}
            <strong>
              {isCarsAssetSelected(assetTypes, assetDetails.assetTypeId)
                ? "gross"
                : "net"}
            </strong>{" "}
            cost of{" "}
            <strong>
              {currencyNoDecimalFormatter.format(assetDetails.purchasePrice)}
            </strong>
            , over a term of{" "}
            <strong>
              {assetDetails.termInMonths}{" "}
              {assetDetails.termInMonths > 1 ? " months" : " month"}
            </strong>
            , with a deposit of{" "}
            <strong>
              {currencyNoDecimalFormatter.format(assetDetails.deposit)}
            </strong>
            {assetDetails.lumpsumset === "yes" && (
              <>
                , and final lump sum of{" "}
                <strong>
                  {currencyNoDecimalFormatter.format(assetDetails.finalLumpSum)}
                </strong>
              </>
            )}
            . Your commission is{" "}
            <strong>
              {numberTwoDecimalsFormatter.format(
                quoteSummary.commissionInPercents * 100
              )}
              %
            </strong>
          </div>
          <FixedWidthTextLink onClick={handleEditClick}>
              <div className="editicon"><EditIcon /></div>
              <div>Edit</div>
          </FixedWidthTextLink>
        </SummaryHeader>
      )}
      {isEditing === true && (
        <FormContainer>
          <QuoteSummaryEditForm
            onSubmit={onSubmit}
            initialValues={assetDetails}
            onValuesChange={onValuesChange}
            assetTypes={assetTypes}
            footer={Footer(handleCancelClick)}
          />
        </FormContainer>
      )}
      <H3>Your quote</H3>
      <label>
        Quotes can change if financial circumstances differ from our initial
        assessment.
      </label>
      <QuoteInformationTable
        data={quoteSummary}
        isLoading={isLoading}
        // showFinalLumpSum={isCarsAssetSelected(
        //   assetTypes,
        //   assetDetails.assetTypeId
        // )}
        showFinalLumpSum={true}
        handleSaveQuoteClick={() => { setIsSaveMenuShown(true); trackButtonClick('Button', 'Save quote'); }}
        hideSaveQuote={state.isFromReferenceNumber}
      />

      <Container>
        <br />
        <h3>Your commission</h3>
        <label>
          Your commission (
          {numberTwoDecimalsFormatter.format(
            quoteSummary.commissionInPercents * 100
          )}
          %)
        </label>
        <strong>{formatter.format(quoteSummary.commission)}</strong>
      </Container>

      {
        state.isFromReferenceNumber &&
        !state.quoteSummary.isCompleted &&
        <Container>
          <br />
          <h3>Confirm your email address</h3>
          <Form
            name="form-email"
            key="broker"
            onSubmit={() => { }}
            initialValues={{ formKey: "broker" }}
            onChange={(e) => { }}
            render={({ handleSubmit }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <input
                  autoComplete="off"
                  name="hiddenone"
                  type="text"
                  style={{ display: "none" }}
                />
                <FormSpy
                  subscription={{ values: true, valid: true, pristine: true }}
                  onChange={handleFormSpyOnChange}
                />
                <BrokerDetailsFieldsSimplified 
                  onBlurBrokerDetailsHandler={onBlurBrokerDetailsHandler}
                />
              </form>
            )}
          />
        </Container>
      }

      <Container>
        <br />
        <Warning>
          The quotes are for indicative purposes ONLY and do not constitute an
          offer. A full credit and affordability check will be carried out and
          your actual quote may vary.
          <br />
          <br />
          Broker Portal is for credit brokers and other intermediaries only.
          These quotes should not be directly distributed to the customer.
        </Warning>
      </Container>
      <PrevNextButtons
        handleBackClick={state.isFromReferenceNumber ? handleBackClickReturnHomePage : handleBackClick}
        handleNextClick={state.isFromReferenceNumber ? handleSubmitToLombardClick : handleNextClick}
        nextBtnText={state.isFromReferenceNumber ? `Submit changes` : `Next step: ${getNextStageName()}`}
        backBtnText={state.isFromReferenceNumber ? `Cancel & return to homepage` : null}
        // nextBtnHidden={state.isFromReferenceNumber && (state.quoteSummary.isCompleted || !emailIsValid)}
        nextBtnHidden={state.isFromReferenceNumber && state.quoteSummary.isCompleted}
      />
      {isSaveMenuShown && (
        <SaveQuote
          handleClose={() => setIsSaveMenuShown(false)}
          quoteId={state.quoteSummary.quoteId}
        />
      )}
    </Container>
  );
};

export default QuoteSummary;
