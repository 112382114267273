export default {
  standardHeaders: ["Asset", "Max. term", "Max age at end of agreement"],
  Cars: {
    headers: [
      "Asset",
      "Max. term",
      "Max. age at end of agreement*",
      "*with balloon",
    ],
    rows: [["Cars", "84 months", "10 years", "7 years"]],
  },
  "Light Commercial Vehicles (LCV)": {
    headers: [
      "Asset",
      "Max. term",
      "Max. age at end of agreement",
      "*with balloon",
    ],
    rows: [["Light Commercial Vehicle", "84 months", "10 years", "7 years"]],
  },
  "Buses & Coaches": {
    rows: [
      ['Bus / Coach','120 months','15 years'],
      ['Mini Bus','84 months','10 years'],
    ],
  },
  "Heavy Commercial Vehicles (HCV)": {
    rows: [['HCV / HGV','84 months','10 years'],],
  },
  Trailers: {
    rows: [['Trailers','120 months','15 years'],],
  },
  Solar: {
    rows: [
      [
        "Solar Panels",
        "84 months",
        "15 years"
      ],
    ],
  },
  Construction: {
    rows: [
      ['Overhead & Gantry Cranes','60 months','7 years'],
      ['Hydraulic Excavators <6tonnes','60 months','7 years'],
      ['Backhoe Loaders','60 months','7 years'],
      ['Wheel Loaders','60 months','7 years'],
      ['All terrain & Utility Vehicles','60 months','7 years'],
      ['Hydraulic Excavators >6tonnes','84 months','10 years'],
      ['Bull Dozers','84 months','10 years'],
      ['Tower Crane','84 months','10 years'],
      ['Dump Trucks','84 months','10 years'],
      ['Mobile Crane','120 months','15 years'],
    ],
  },
  "Material Handling Equipment": {
    rows: [
      ['Forklifts','60 months','7 years'],
      ['Access Platforms (Diesel & LPG)','84 months','10 years'],
      ['Access Platforms (Boom & Scissor Lift)','84 months','10 years'],
      ['Telehandler','84 months','10 years'],
      ['Crane (Tower)','84 months','10 years'],
      ['Crane (Mobile)','120 months','15 years'],
    ],
  },
  Production: {
    rows: [
      ['Sawing Machines','60 months','7 years'],
      ['Woodwork Drilling Machines','60 months','7 years'],
      ['General Woodworking','60 months','7 years'],
      ['Packaging Packing/Wrapping','84 months','10 years'],
      ['Lathes','84 months','10 years'],
      ['Embroidery Machines','84 months','10 years'],
      ['Lasers/Cutters','84 months','10 years'],
      ['Packaging Conveyors','84 months','10 years'],
      ['Robotic & Spot Welding Machines','84 months','10 years'],
      ['Sewing Machine','84 months','10 years'],
      ['Expanded Polystyrene & Foam Moulding','84 months','10 years'],
      ['Sheet Metal Pressbrakes','120 months','15 years'],
      ['Machine Tools (Machine Centres)','120 months','15 years'],
    ],
  },
  "Hi Tech": {
    rows: [
      ['16/35mm Film Cameras & Telecines / Datacines','60 months','5 years'],
      ['Laboratory Analysers','60 months','5 years'],
      ['Film & TV- Video Cameras','60 months','7 years'],
      ['Audio Outboard Equipment','60 months','7 years'],
      ['Ultrasound','60 months','7 years'],
      ['X-Ray','60 months','7 years'],
      ['Loudspeakers','84 months','10 years'],
      ['Film Video Lighting Grip','84 months','10 years'],
    ],
  },
  Agriculture: {
    rows: [
      ['Demountable Sprayers / Sprayers Hedgers / Grass Trimmers','60 months','7 years'],
      ['Timber Equipment (Mobile Chippers/Shredders)','60 months','7 years'],
      ['All terrain and Utility Vehicles','60 months','7 years'],
      ['Self Propelled Sprayers','84 months','10 years'],
      ['Milking Machinery – Portable or Fixed & Robot','120 months','15 years'],
      ['Tractors Conventional & Tracked','120 months','15 years'],
      ['Combine Harvesters','120 months','15 years'],
    ],
  },
  Catering: {
    rows: [
      ['All','60 months','5 years'],
    ],
  },
  Miscellaneous: {
    rows: [
      ['All','60 months','5 years'],
    ],
  },
  Furniture: {
    rows: [
      ['All','60 months','5 years'],
    ],
  },
  Containers: {
    rows: [
      ['All','60 months','5 years'],
    ],
  },
  IT: {
    rows: [
      ['All','60 months','5 years'],
    ],
  },
  Motorcycles : {
    rows: [
      ['All','36 months','3 years'],
    ],
  },
  Caravans: {
    rows: [
      ['All','60 months','5 years'],
    ],
  },
  "Renewable Energy": {
    rows: [
      [
        "Anaerobic Digester",
        "84 months",
        "15 years"
      ],
      [
        "Battery Storage",
        "84 months",
        "15 years"
      ],
      [
        "Biomass Boilers",
        "84 months",
        "15 years"
      ],
      [
        "CHP (Combined Heat and Power)",
        "84 months",
        "15 years"
      ],
      [
        "Electric Vehicle Chargers",
        "60 months",
        "5 years"
      ],
      [
        "Gasification Plants – Energy from Waste",
        "84 months",
        "15 years"
      ],
      [
        "Heat Pumps",
        "84 months",
        "15 years"
      ],
      [
        "LED Lighting",
        "60 months",
        "5 years"
      ],
      [
        "Onshore Wind Turbines",
        "84 months",
        "15 years"
      ],
      [
        "Other",
        "60 months",
        "5 years"
      ],
      [
        "Hydro",
        "84 months",
        "15 years"
      ],
      [
        "Uninterrupted Power Supply (UPS)",
        "84 months",
        "15 years"
      ]
    ],
  },
  "Energy Efficiency": {
    rows: [
      [
        "BMS (Building Management Systems)",
        "60 months",
        "5 years"
      ],
      [
        "Emissions Monitoring Systems",
        "60 months",
        "5 years"
      ],
      [
        "MVHR (Mechanical Ventilation with Heat Recovery)",
        "60 months",
        "5 years"
      ],
      [
        "Voltage Optimisers",
        "60 months",
        "5 years"
      ],
      [
        "Other",
        "60 months",
        "5 years"
      ]
    ],
  },
  Healthcare: {
    rows: [
      ['All','60 months','Subject to review'],
    ],
  },
  Municipal: {
    rows: [
      ['All','60 months','5 years'],
    ],
  },
  Rail: {
    rows: [
      ['All','60 months','Subject to review'],
    ],
  }
};
