import { brokerAuth, sessionId } from "../../resources/utils";

const API_URL = process.env.REACT_APP_API_URL;

const buiildDataForQuoteGeneration = (quoteData) => {
  let dataToSend = {
    ...quoteData.assetDetails,
    ...quoteData.businessDetails,
  };
  dataToSend.deposit = dataToSend.deposit ? dataToSend.deposit * 1 : 0;
  dataToSend.commission = dataToSend.commission ? dataToSend.commission * 1 : 0;

  dataToSend.brokerFirmIdentifier = brokerAuth();
  if (!dataToSend.lumpsumset || dataToSend.lumpsumset === "no") {
    dataToSend.finalLumpSum = 0;
  }

  if (dataToSend.lastDirectorAppointed) {
    let splittedDateText = dataToSend.lastDirectorAppointed.split("/");
    dataToSend.lastDirectorAppointed = new Date(
      Date.UTC(splittedDateText[1], splittedDateText[0] - 1, 1)
    );
  }
  if (dataToSend.lastPartnerAppointed) {
    let splittedDateText = dataToSend.lastPartnerAppointed.split("/");
    dataToSend.lastPartnerAppointed = new Date(
      Date.UTC(splittedDateText[1], splittedDateText[0] - 1, 1)
    );
  }
  if (dataToSend.startedTradingDate) {
    let splittedDateText = dataToSend.startedTradingDate.split("/");
    dataToSend.startedTradingDate = new Date(
      Date.UTC(splittedDateText[1], splittedDateText[0] - 1, 1)
    );
  }

  dataToSend.netAssetsMoreThan3Millions = quoteData.businessDetails.netAssets == 1 ? true : false ;

  // console.log(dataToSend.assetDetails);

  return dataToSend;
}

class ApiFetchService {
  static FetchAssetTypes = async () => {
    const response = await fetch(`${API_URL}/api/lombard/AssetTypes`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return await response.json();
  };

  static PostContactUs = async (data, quoteData) => {
    let dataToSend = buiildDataForQuoteGeneration(quoteData); 
    dataToSend = Object.assign({...data, webSessionId: sessionId()}, dataToSend);
  
    const response = await fetch(
      `${API_URL}/api/lombard/ContactUs/speaktotheteam`,
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrer: "no-referrer",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToSend),
      }
    );

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    return await response.json();
  };

  static GenerateQuote = async (quoteData) => {
    const dataToSend = buiildDataForQuoteGeneration(quoteData);

    const response = await fetch(`${API_URL}/api/lombard/Quotes`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrer: "no-referrer",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataToSend),
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    return await response.json();
  };

  static SaveQuote = async (quoteId, email) => {
    let dataToSend = {
      brokerFirmIdentifier: brokerAuth(),
      email: email,
    };

    const response = await fetch(
      `${API_URL}/api/lombard/Quotes/${quoteId}/saveforlater`,
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrer: "no-referrer",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToSend),
      }
    );

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    return await response.json();
  };

  static RetrieveQuote = async (retrievalId) => {
    const response = await fetch(
      `${API_URL}/api/lombard/Quotes/${retrievalId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      const message = await response.json();
      throw new Error(message.title);
    }

    return await response.json();
  };

  static RetrieveQuoteByReferenceNumber = async (refNum, quoteId) => {
    const response = await fetch(
      `${API_URL}/api/lombard/Quotes/ReferenceNumber/${refNum}/${quoteId}`,
      {
        headers: { "Content-Type": "application/json"},
      }
    );

    if (!response.ok) {
      const message = await response.json();
      throw new Error(message.title);
    }

    return await response.json();
  };

  static SubmitFinanceApplication = async (quoteId, supportingDetails, referenceNumber, prevQuoteId) => {
    // console.log('>>> SubmitFinanceApplication', quoteId, supportingDetails, referenceNumber, prevQuoteId);

    let dataToSend = {
      quoteId: quoteId,
      
      brokerFirmIdentifier: brokerAuth(),
      brokersEmailAddress: supportingDetails.broker.email,
      brokersFirmName: supportingDetails.broker.firmName,
      brokersFirstName: supportingDetails.broker.firstName,
      brokersLastName: supportingDetails.broker.lastName,
      brokersPhoneNumber: supportingDetails.broker.phoneNumber,

      clientDetails: {
        clientFirstName: supportingDetails.client?.clientFirstName,
        clientLastName: supportingDetails.client?.clientLastName,
        tradingName: supportingDetails.client?.tradingName,
        businessName: supportingDetails.client?.registeredBusinessName,
        businessAddressPostcode: supportingDetails.client?.address?.postcode,
        businessAddressCity: supportingDetails.client?.address?.city,
        businessAddress: supportingDetails.client?.address?.addressLine1,
        businessAddress2: supportingDetails.client?.address?.addressLine2,
        annualTurnover: supportingDetails.client?.annualTurnover,
        netAssets: supportingDetails.client?.netAssets,
      },

      suppliersCompanyName: supportingDetails.supplierdetails?.companyName,
      suppliersContactName: supportingDetails.supplierdetails?.contactName,
      suppliersPhoneNumber: supportingDetails.supplierdetails?.phoneNumber,
      suppliersEmailAddress: supportingDetails.supplierdetails?.email,
      suppliersAddressPostCode:
        supportingDetails.supplierdetails?.address?.postcode,
      suppliersAddressCity: supportingDetails.supplierdetails?.address?.city,
      suppliersAddress:
        supportingDetails.supplierdetails?.address?.addressLine1,
      suppliersAddress2:
        supportingDetails.supplierdetails?.address?.addressLine2,
      additionalDetails:
        supportingDetails.additionaldetails?.additionalDetails,

      assetDetails: [],
    };
    
    if (referenceNumber) {
      dataToSend.prevReferenceNumber = referenceNumber;
      dataToSend.prevQuoteId = prevQuoteId;
    }
    // console.log('>>> SubmitFinanceApplication, dataToSend', dataToSend);
    
    for (let key in supportingDetails) {
      if (key.startsWith("assetdetails_")) {
        dataToSend.assetDetails.push({
          vehicleRegNumber: supportingDetails[key].vehicleRegNumber,
          make: supportingDetails[key].vehicleMake,
          model: supportingDetails[key].vehicleModel,
          registrationYear: supportingDetails[key].vehicleRegistrationYear,
          currentMileage: supportingDetails[key].currentMileage,
          expectedAnnualMileage: supportingDetails[key].expectedAnnualMileage,
          description: supportingDetails[key].description,
          dateOfManufacture: supportingDetails[key].manufactureDate
            ? new Date(
                supportingDetails[key].manufactureDate.split("/")[1],
                supportingDetails[key].manufactureDate.split("/")[0] - 1,
                1
              )
            : undefined,
        });
      }
    }
    
    // console.log('>>> SubmitFinanceApplication, dataToSend', {
    //   lombardQuoteId: quoteId,
    //   brokerFirmIdentifier: brokerAuth(),
    // });

    const financeApplicationResponse = await fetch(
      `${API_URL}/api/lombard/FinanceApplications`,
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrer: "no-referrer",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          lombardQuoteId: quoteId,
          brokerFirmIdentifier: brokerAuth(),
        }),
      }
    );

    if (!financeApplicationResponse.ok) {
      const message = `An error has occured: ${financeApplicationResponse.status}`;
      throw new Error(message);
    }

    const result = await financeApplicationResponse.json();
    const financeApplicationId = result.financeApplicationId;

    const url = `${API_URL}/api/lombard/FinanceApplications/${financeApplicationId}/${referenceNumber ? 'complete-edited' : 'complete'}`;

    const response = await fetch(
      url,
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrer: "no-referrer",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToSend),
      }
    );

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    return await response.json();
  };

  static SubmitApplicationRating = async (
    hasConsentBeenGivenToBeContacted,
    score,
    comments,
    referralId,
    brokerDetails
  ) => {
    let dataToSend = {
      hasConsentBeenGivenToBeContacted,
      score,
      comments,
      brokerFirmIdentifier: brokerAuth(),
      webSessionId: sessionId(),
      isLombardRating: true,
      referralId,
    };

    if (hasConsentBeenGivenToBeContacted) {
      dataToSend.firstName = brokerDetails.firstName;
      dataToSend.lastName = brokerDetails.lastName;
      dataToSend.companyName = brokerDetails.firmName;
      dataToSend.phoneNumber = brokerDetails.phoneNumber;
      dataToSend.emailAddress = brokerDetails.email;
    }

    const response = await fetch(`${API_URL}/api/Ratings/referral`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrer: "no-referrer",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataToSend),
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    return await response.json();
  };
}

export default ApiFetchService;
