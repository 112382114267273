import React from "react";
import styled from "styled-components";
import NumberFormat from "react-number-format";
import { media, colors } from "../theming/variables";
import { colours } from "resources/global-styles";

const Numeric = styled(NumberFormat)`
  width: ${(props) => (props.fullwidth === "" ? "90%" : "100%")};
  height: 54px;
  font-size: 18px;
  color: ${colors.darkGrey};
  padding: 15px 45px 15px 15px;
  border: none;
  background-color: ${(props) => (props.disabled ? colours.lightGrey : colours.input_bg)};

  border-radius: 8px;
  border: 1px solid ${colours.field_border};

  outline: 0;
  background-position: center right 15px;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  background-image: ${(props) =>
    props.valid === "true" ? "url(/images/input-valid-icon.svg)" : "none"};

    &:hover:focus {
      border: none;
      box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
    }

    &:hover {
      border: 2px solid ${colours.input_border};
    }
  
    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px ${colours.field_border}, 0 0 0 2px ${colours.input_bg}, 0 0 0 4px ${colours.input_border};
    }

  @media ${media.tablet} {
    width: ${(props) => (props.fullwidth === "true" ? "90%" : "50%")};
  }
`;

const NumericInput = ({
  value,
  fullWidth,
  valid,
  onChange,
  isCurrency,
  onBlur,
  suffix,
  decimalScale,
  placeholder,
  ignoreThousandsSeparator,
  tabIndex,
  fixedDecimalScale,
  disabled,
}) => {
  const prefix = isCurrency ? "£" : "";
  const useFullWidth = fullWidth !== undefined ? fullWidth.toString() : "true";

  return (
    <Numeric
      value={value >= 0 ? value : ""}
      onValueChange={(val) => onChange(val.floatValue)}
      onBlur={onBlur}
      valid={valid ? valid.toString() : undefined}
      decimalScale={isCurrency ? 2 : decimalScale ?? 0}
      fixedDecimalScale={isCurrency ? true : (fixedDecimalScale ?? false)}
      thousandSeparator={ignoreThousandsSeparator ? false : true}
      prefix={prefix}
      suffix={suffix}
      fullwidth={useFullWidth}
      maxLength="255"
      placeholder={placeholder ? placeholder : isCurrency ? "£" : ""}
      allowNegative={false}
      tabIndex={tabIndex ? tabIndex : 1}
      disabled={disabled ? true : false}
    />
  );
};

export default NumericInput;
